
import { defineComponent } from 'vue';
import ShapeMask from '@/components/atoms/ShapeMask.vue';

export default defineComponent({
  name: 'HowCanWeHelpComp',
  components: {
    ShapeMask,
  },
});
